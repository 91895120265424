import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
Vue.use(axios)

export default new Vuex.Store({
  state: {
    auth: {
      apiUsername: "",
      apiPassword: "",
      userId: localStorage.getItem("userId") || null,
      token: localStorage.getItem("token") || null,
    },
    data: {
      windowHeight: document.documentElement.scrollHeight,
      enrollmentPortalUrl: 'https://login.smartdealerportal.com/login',
      supportPhone: "855-550-0085",
      supportEmail: "info@hondadigitaldealer.com",
      heroMessages: [
        {
          title: "digital strategies",
          description: "simplified for your dealership.",
        },
        {
          title: "innovative tools",
          description: "customized for your dealership.",
        },
        {
          title: "prompt support",
          description: "dedicated to your dealership.",
        },
      ],
      heroMessagesVendorLaunch: [
        {
          title: "two new hds-certified website providers!",
          description: "dealer alchemist & intice",
        },
        {
          title: "innovative tools",
          description: "customized for your dealership.",
        },
        {
          title: "prompt support",
          description: "dedicated to your dealership.",
        },
      ],
      demoRequestUrls: [
        {
          // Dealer Websites
          category_id: "5658",
          url: "https://forms.office.com/pages/responsepage.aspx?id=9SfFtYieMku_u41EJZYWWqBvtwJUpIdCiAxHMOrJyRhUMDNTRkRHMzhGOEtIVDQ1RjdKOU5JNENCMC4u&web=1&wdLOR=c01081585-25B8-4611-BEEB-B2EA0B52B22B",
        },
        {
          // Digital Advertising
          category_id: "4649",
          url: "https://forms.office.com/pages/responsepage.aspx?id=9SfFtYieMku_u41EJZYWWqBvtwJUpIdCiAxHMOrJyRhUM1VRSFRUWTNSTERZWFhUVEtOOTdNWElWMS4u&web=1&wdLOR=c94EB613B-BDF7-4115-9DA5-10500C6B70C6",
        },
        {
          // Digital Retailing
          category_id: "3881",
          url: "https://forms.office.com/pages/responsepage.aspx?id=9SfFtYieMku_u41EJZYWWqBvtwJUpIdCiAxHMOrJyRhUQTBaR09FNEJHMUo4SzA4WU5PU0hZNzFBTC4u&web=1&wdLOR=c00D4284F-CB5A-416C-A77D-F75ADE8310C8",
        },
        {
          // Chat / SMS (marketplace services)
          category_id: "4639",
          url: "https://forms.office.com/pages/responsepage.aspx?id=9SfFtYieMku_u41EJZYWWqBvtwJUpIdCiAxHMOrJyRhUQU1PR0NVN1dUNVo0Ulo4N0k2QTNNMzdISS4u&web=1&wdLOR=c2C516F1A-2D14-483B-9347-D6D04F4C3165",
        },
        {
          // Creative Assets (marketplace services)
          category_id: "5388",
          url: "https://forms.office.com/pages/responsepage.aspx?id=9SfFtYieMku_u41EJZYWWqBvtwJUpIdCiAxHMOrJyRhUQU1PR0NVN1dUNVo0Ulo4N0k2QTNNMzdISS4u&web=1&wdLOR=c2C516F1A-2D14-483B-9347-D6D04F4C3165",
        },
        {
          // Lead Enrichment (marketplace services)
          category_id: "9349",
          url: "https://forms.office.com/pages/responsepage.aspx?id=9SfFtYieMku_u41EJZYWWqBvtwJUpIdCiAxHMOrJyRhUQU1PR0NVN1dUNVo0Ulo4N0k2QTNNMzdISS4u&web=1&wdLOR=c2C516F1A-2D14-483B-9347-D6D04F4C3165",
        },
        {
          // Call Tracking (marketplace services)
          category_id: "3864",
          url: "https://forms.office.com/pages/responsepage.aspx?id=9SfFtYieMku_u41EJZYWWqBvtwJUpIdCiAxHMOrJyRhUQU1PR0NVN1dUNVo0Ulo4N0k2QTNNMzdISS4u&web=1&wdLOR=c2C516F1A-2D14-483B-9347-D6D04F4C3165",
        },
        {
          // Reputation Management (marketplace services)
          category_id: "9309",
          url: "https://forms.office.com/pages/responsepage.aspx?id=9SfFtYieMku_u41EJZYWWqBvtwJUpIdCiAxHMOrJyRhUQU1PR0NVN1dUNVo0Ulo4N0k2QTNNMzdISS4u&web=1&wdLOR=c2C516F1A-2D14-483B-9347-D6D04F4C3165",
        },
        {
          // Trade-in (marketplace services)
          category_id: "2514",
          url: "https://forms.office.com/pages/responsepage.aspx?id=9SfFtYieMku_u41EJZYWWqBvtwJUpIdCiAxHMOrJyRhUQU1PR0NVN1dUNVo0Ulo4N0k2QTNNMzdISS4u&web=1&wdLOR=c2C516F1A-2D14-483B-9347-D6D04F4C3165",
        },
        {
          // EV Hub (marketplace services)
          category_id: "1402",
          url: "https://forms.office.com/pages/responsepage.aspx?id=9SfFtYieMku_u41EJZYWWqBvtwJUpIdCiAxHMOrJyRhUQU1PR0NVN1dUNVo0Ulo4N0k2QTNNMzdISS4u&web=1&wdLOR=c2C516F1A-2D14-483B-9347-D6D04F4C3165",
        },
      ],
      minutePasswords: {
        0: "DmfyxpjZntedszAT",
        1: "eNECptgxbw6vuZep",
        2: "yQeHp5AuTpUnJmTq",
        3: "vhAQvSTB8ppmcP5L",
        4: "rGA247iocBKeAHLx",
        5: "oycgxR0nae6NgUSK",
        6: "hF26Iiz33hdC323n",
        7: "RfaBbZVOwnaTC9kx",
        8: "0ShZP0o81qCvmiYP",
        9: "s4EXk3TFyJN25rUl",
        10: "e3B2cmVfBSmlwfSi",
        11: "QRvJ43U1f12lCUco",
        12: "Qpp3jnqCWu3Gc0Uk",
        13: "bwPqW7xuDI2gifrb",
        14: "B22gThpy8wt1sgJs",
        15: "0V3zu18QGYLa64hQ",
        16: "Q4LVxhlXRKvMppka",
        17: "OX7vefJwV70WJfFx",
        18: "LINkipZDNxSYnzna",
        19: "TRM3fI6LcTS0hXov",
        20: "7oaDFiFCAds4m6xz",
        21: "Eco0YBl5liENKHNA",
        22: "F8kTAoOyWSW6u5Xj",
        23: "Mx7twhpgTN9jxOjh",
        24: "zFY2pnP70iezYnLN",
        25: "uYS6eF4HciAGUUue",
        26: "2oxPijIa64Twppzy",
        27: "aL9G9qaqENZykhhL",
        28: "EtKdQyxExEYAcRQH",
        29: "SOg2sYnkwI7tA8Cf",
        30: "95ZXdOW47VnA4t7H",
        31: "mKpaEdnXJodSEkLN",
        32: "LaMUfJ6zNohg9FSG",
        33: "01F5oIVgTmakG3or",
        34: "DjAP1guBvtWbmqAS",
        35: "YwIIGkRoybVh16Wh",
        36: "0BcOJrUaxKtBoPJH",
        37: "MKfEmp2ISxeVCWtI",
        38: "vScQjQCvKPjybdcm",
        39: "lMJFYlurOjfXHMNV",
        40: "7EIX7Sv0dfjD2MjO",
        41: "USGIyBSFMxCKnzeE",
        42: "hGZZtpTLFlIbsknt",
        43: "9sjgFBDYwSGcDIbQ",
        44: "Wou6rLh4ywvQIalM",
        45: "0DKXUqSRgczsoxcw",
        46: "U8XsHEN5M3XfIrCT",
        47: "Jg3eNpMOVwF1NOSR",
        48: "NjSqMirFs0OwmoTL",
        49: "EjRAM89VOYx3iPHu",
        50: "OaLxL7V7B3jAA66V",
        51: "L6AvOh0p57Jl8v2Y",
        52: "l88H3pS86Vnhxz5a",
        53: "9ZbLwTKyjLCCTmSP",
        54: "mu0nTTCa7aXncTjQ",
        55: "UxyLSn66zoomNmkI",
        56: "nsl6VyZqLYEqWwGI",
        57: "blOwxaD5ffMvWItD",
        58: "ogggePowVTapEaQc",
        59: "sQhJuXN6WapDIWFV",
      },
    },
    mobileNavActive: false,
    modalActive: false,
    supportActive: false,
    videoActive: false,
    videoActiveUrl: "",
    enrollmentActive: false,
    aboutDropdownActive: false,
    deleteConfirmActive: false,
    marketplaceServicesDropdownActive: false,

    // PureCars, Stream Companies, Adpearance
    lxDigAdVendors: ['290187', '978220', '680935'],
  },

  getters: {
    loggedIn(state) {
      if (state.auth.userId && state.auth.token) {
        return true;
      } else {
        return false;
      }
    },
  },

  mutations: {
    closeAboutDropdown(state) {
      state.aboutDropdownActive = false;
    },

    closeMarketplaceServicesDropdown(state) {
      state.marketplaceServicesDropdownActive = false;
    },

    closeNavDropdowns(state) {
      state.marketplaceServicesDropdownActive = false;
      state.aboutDropdownActive = false;
    },

    toggleMobileNav(state) {
      state.mobileNavActive = !state.mobileNavActive;
    },

    toggleAboutDropdown(state) {
      state.aboutDropdownActive = !state.aboutDropdownActive;
    },

    toggleMarketplaceServicesDropdown(state) {
      state.marketplaceServicesDropdownActive = !state.marketplaceServicesDropdownActive;
    },

    toggleModal(state) {
      state.windowHeight = document.documentElement.scrollHeight;
      state.modalActive = !state.modalActive;
      state.aboutDropdownActive = false;
      state.deleteConfirmActive = false;
    },

    toggleSupport(state) {
      state.supportActive = !state.supportActive;
    },

    toggleVideo(state) {
      state.videoActive = !state.videoActive;
    },

    toggleEnrollment(state) {
      state.enrollmentActive = !state.enrollmentActive;
    },

    toggleDeleteConfirm(state) {
      state.deleteConfirmActive = !state.deleteConfirmActive;
    },

    setVideoUrl(state, payload) {
      state.videoActiveUrl = payload;
    },

    closeEverything(state) {
      state.modalActive = false;
      state.supportActive = false;
      state.videoActive = false;
      state.enrollmentActive = false;
      state.videoActiveUrl = "";
      state.aboutDropdownActive = false;
      state.mobileNavActive = false;
      state.deleteConfirmActive = false;
    },

    retrieveUserId(state, userId) {
      state.auth.userId = userId;
    },

    retrieveToken(state, token) {
      state.auth.token = token;
    },

    destroyUserId(state) {
      state.auth.userId = null;
    },

    destroyToken(state) {
      state.auth.token = null;
    },

    generateApiCreds(state) {

      state.auth.apiUsername = 'enderman';

      let d = new Date();
      let minute = d.getMinutes();
      let password =
        state.data.minutePasswords[minute] +
        "." +
        Math.random()
          .toString(36)
          .replace("0.", "");

      state.auth.apiPassword = password;

    },
  },

  actions: {
    retrieveToken(context, credentials) {
      context.commit("generateApiCreds");
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("email", credentials.email);
        formData.append("password", credentials.password);

        axios
          .post(process.env.VUE_APP_API_PATH + "login", formData, {
            auth: {
              username: context.state.auth.apiUsername,
              password: context.state.auth.apiPassword,
            },
          })
          .then(function (resp) {
            const token = resp.data.token;
            const userId = resp.data.userId;
            localStorage.setItem("userId", userId);
            localStorage.setItem("token", token);
            context.commit("retrieveToken", token);
            context.commit("retrieveUserId", userId);
            resolve(resp);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    destroyToken(context) {
      context.commit("generateApiCreds");
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("userId", context.state.auth.userId);

          axios
            .post(process.env.VUE_APP_API_PATH + "logout", formData, {
              auth: {
                username: context.state.auth.apiUsername,
                password: context.state.auth.apiPassword,
              },
            })
            .then(function (resp) {
              localStorage.removeItem("userId");
              localStorage.removeItem("token");
              context.commit("destroyUserId");
              context.commit("destroyToken");
              resolve(resp);
            })
            .catch(function (err) {
              localStorage.removeItem("userId");
              localStorage.removeItem("token");
              context.commit("destroyUserId");
              context.commit("destroyToken");
              reject(err);
            });
        });
      }
    },

    checkUser(context) {
      context.commit("generateApiCreds");
      const formData = new FormData();

      formData.append("user_id", context.state.auth.userId);
      formData.append("token", context.state.auth.token);

      axios.post(process.env.VUE_APP_API_PATH + "checker", formData, {
        auth: {
          username: context.state.auth.apiUsername,
          password: context.state.auth.apiPassword,
        },
      })
        .catch(function () {
          localStorage.removeItem("userId");
          localStorage.removeItem("token");
          context.commit("destroyUserId");
          context.commit("destroyToken");
        });
    },
  },
});
